import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})

export class PlatformService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  get tbBreakpoint(): boolean {
    if (!this.isBrowser) return false;
    return window.innerWidth <= 767;
  }

  get dtBreakpoint(): boolean {
    if (!this.isBrowser) return false;
    return window.innerWidth > 1279;
  }
}
