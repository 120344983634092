import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { PlatformService } from '@services/platform.service';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';

@Component({
  selector: 'symfa-cookie-message',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  providers: [CookieService],
  templateUrl: './symfa-cookie-message.component.html',
  styleUrls: ['./symfa-cookie-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymfaCookieMessageComponent implements OnInit, OnDestroy {
  // public showCookieMessage: boolean = false;
  private sub$: Subscription = new Subscription();

  constructor(
    private platformService: PlatformService,
    // private router: Router,
    // private renderer: Renderer2,
    // @Inject(DOCUMENT) private document: Document,
    // private cdr: ChangeDetectorRef,
    private ccService: NgcCookieConsentService
  ) {}

  public ngOnInit(): void {
    if (this.platformService.isBrowser) {
      // subscribe to cookieconsent observables to react to main events
      this.sub$.add(this.ccService.popupOpen$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        }));

      this.sub$.add(this.ccService.popupClose$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        }));

      this.sub$.add(this.ccService.initializing$.subscribe(
        (event) => {
          // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
          console.log(`initializing: ${JSON.stringify(event)}`);
        }));

      this.sub$.add(this.ccService.initialized$.subscribe(
        () => {
          // the cookieconsent has been successfully initialized.
          // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
          console.log(`initialized: ${JSON.stringify(event)}`);
        }));

      this.sub$.add(this.ccService.initializationError$.subscribe(
        (event) => {
          // the cookieconsent has failed to initialize...
          console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
        }));

      this.sub$.add(this.ccService.statusChange$.subscribe(
        (event) => {
          // you can use this.ccService.getConfig() to do stuff...
        }));

      this.sub$.add(this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        }));

      this.sub$.add(this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
        }));
      // this.sub$ = this.router.events.subscribe((event) => {
      //   this.renderer.addClass(this.document.body, 'scrollable');
      //   this.showCookieMessage = false;
      //   if (event instanceof NavigationEnd) {
      //     const isCookiePage = event.url.includes('/cookies');
      //     const cookieConsent = localStorage.getItem('SymfaCookieConsent')
      //     if (cookieConsent || isCookiePage) {
      //       return;
      //     }
      //
      //     setTimeout(() => {
      //       this.showCookieMessage = true;
      //       this.renderer.removeClass(this.document.body, 'scrollable');
      //       this.cdr.markForCheck();
      //     }, 2000);
      //   }
      // });
    }
  }

  public ngOnDestroy(): void {
    this.sub$ && this.sub$.unsubscribe();
  }

  // acceptAll() {
  //   localStorage.setItem('SymfaCookieConsent', JSON.stringify(true));
  //   this.showCookieMessage = false;
  //   this.renderer.addClass(this.document.body, 'scrollable');
  // }
  //
  // goToCookiePolicy() {
  //   this.router.navigate(['/cookies']);
  // }
}
