import { Injectable } from '@angular/core';
import { CookieTypeEnum } from '@enums/cookie-type.enum';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieConfigurationService {
  public COOKIE_PREFERENCES_KEY = 'cookiePreferences';
  private functionalCookies: string[] = [
    '__cf_bm',
    'TawkConnectionTime',
    'twk_idm_key',
    'lidc',
    'li_gc',
    'cf_clearance',
    'bcookie',
    'bscookie',
    'AEC',
    'CONSENT',
    '__Secure-ENID',
    'HSID',
    'SIDCC',
    'SID',
    '__Secure-3PSIDCC',
    '__Secure-1PSIDCC',
    '__Secure-3PAPISID',
    '__Secure-1PAPISID',
    '__Secure-3PSID',
    '__Secure-1PSID',
  ];

  private analyticalCookies: string[] = [
    '_ga',
    '_ga_',
    'sc_is_visitor_unique',
    'is_unique',
    'is_visitor_unique',
    'AnalyticsSyncHistory',
    'sc_medium_source',
    'tawk_uuid_',
    'statcounter_bounce',
    '_gcl_au',
    'SAPISID',
    'SSID',
  ];

  private personalizedAdvertisingCookies: string[] = [
    'UserMatchHistory',
    'li_sugr',
    'ar_debug',
    'APISID',
    'IDE',
    'DSID',
    'NID',
    '1P_JAR',
    'ADS_VISITOR_ID',
    '__Secure-1PSIDTS',
    '__Secure-3PSIDTS',
  ];

  private cookiesConfig: { [key: string]: string[] } = {
    [CookieTypeEnum.Functional]: this.functionalCookies,
    [CookieTypeEnum.Analytical]: this.analyticalCookies,
    [CookieTypeEnum.PersonalizedAdvertising]: this.personalizedAdvertisingCookies,
  };

  constructor(private cookieService: CookieService) {}

  public getRejectedCookieListByPreferences(preferences: { [key: string]: boolean }): string[] {
    return Object.entries(preferences)
      .filter(([_, isAccepted]) => !isAccepted)
      .map(([cookieType]) => this.cookiesConfig[cookieType])
      .flat();
  }

  public deleteRejectedCookies(cookies: string[]): void {
    cookies.forEach((cookie) => {
      if (this.cookieService.check(cookie)) {
        this.cookieService.delete(cookie);
      }
    });
  }
}
