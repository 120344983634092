import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { PlatformService } from '@services/platform.service';
import { TawkChatService } from '@services/tawk-chat.service';
import { filter, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '@services/http.service';
import { ScrollService } from '@services/auto-scroll.service';
import { LocationService } from '@services/location.service';
import { take } from 'rxjs/operators';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isRedirectModal$: Observable<boolean>;
  sub$: Subscription = new Subscription();
  urlsHistory: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private platformService: PlatformService,
    private router: Router,
    private chatService: TawkChatService,
    private httpService: HttpService,
    private scrollService: ScrollService,
    private locationService: LocationService,
    private ccService: NgcCookieConsentService,
  ) {}

  ngOnInit(): void {
    this.sub$.add(
      this.httpService
        .getCollections(`code_snippet_aliases?fields=*.*`)
        .subscribe(async (res: { data: { id: number; sort: number; alias: string }[] }) => {
          await Promise.allSettled(res.data.map((lang) => import(`prismjs/components/prism-${lang.alias}`)));
        }),
    );
    if (this.platformService.isBrowser) {
      window.onload = () => {
        if (!this.ccService.hasAnswered()) {
          setTimeout(() => {
            this.ccService.open();
          }, 5000);
        } else {
          this.ccService.toggleRevokeButton(true);
        }
      };
      this.locationService
        .getCountry()
        .pipe(take(1))
        .subscribe((country) => {
          if (country === 'United Kingdom') {
            this.ccService.destroy();
            const popup = document.querySelector('.cc-window');
            this.ccService.toggleRevokeButton(false);

            if (popup) {
              popup.remove();
            }
            this.router.navigate(['/503']);
          }
        });
      this.urlsHistory = JSON.parse(sessionStorage.getItem('urlsHistory') as string) || [];
      this.sub$.add(
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e) => {
          const url: string = (e as NavigationEnd).url;
          this.urlsHistory.push(url);
          sessionStorage.setItem('urlsHistory', JSON.stringify(this.urlsHistory));
        }),
      );

      this.isRedirectModal$ = this.route.queryParams.pipe(
        map((queryParams) => !!queryParams['rebranding'] && document.referrer.indexOf(environment.originUrl) == -1),
      );

      setTimeout(() => {
        this.chatService.initChat();
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
