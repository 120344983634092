import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { CookieConfigurationService } from '@services/cookie-configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { CookieTypeEnum } from '@enums/cookie-type.enum';

@Injectable()
export class PreventSetCookieInterceptor implements HttpInterceptor {
  cookiePreferences: { [key: string]: boolean } = {
    [CookieTypeEnum.Functional]: true,
    [CookieTypeEnum.Analytical]: true,
    [CookieTypeEnum.PersonalizedAdvertising]: true,
  };
  constructor(private cookieConfigService: CookieConfigurationService, private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const storedPreferences = this.cookieService.get(this.cookieConfigService.COOKIE_PREFERENCES_KEY);
          if (storedPreferences) {
            this.cookiePreferences = JSON.parse(storedPreferences);
          }

          const rejectedCookieListByPreferences = this.cookieConfigService.getRejectedCookieListByPreferences(
            this.cookiePreferences,
          );

          const modifiedHeaders: HttpHeaders = new HttpHeaders();

          const setCookieHeadersValuesList: string[] = event.headers.getAll('Set-Cookie') || [];
          const filteredSetCookieHeadersValues: string[] = setCookieHeadersValuesList.filter((value) => {
            const cookieName = value.split('=')[0].trim();
            return !rejectedCookieListByPreferences.includes(cookieName);
          });

          modifiedHeaders.append('Set-Cookie', filteredSetCookieHeadersValues.join('; '));
          event.headers.keys().forEach((header) => {
            if (header !== 'Set-Cookie') {
              modifiedHeaders.append(header, event.headers.get(header)!);
            }
          });

          return event.clone({ headers: modifiedHeaders });
        }
        return event;
      }),
    );
  }
}
