<div *ngIf="isVisible" class="redirect-modal" [style.background-color]="data.bg_color">
  <button class="redirect-modal__close-button" (click)="close()">
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [attr.fill]="data.exit_icon_color" d="M.333 21.546 21.546.333l2.122 2.121L2.454 23.667z" />
      <path [attr.fill]="data.exit_icon_color" d="m2.454.333 21.213 21.213-2.121 2.121L.333 2.454z" />
    </svg>
  </button>
  <p [style.color]="data.text_color" class="redirect-modal__info">
    <span>{{data.date}}</span>
    <span>{{data.place}}</span>
  </p>
  <h1 [style.color]="data.text_color" class="redirect-modal__header" [title]="data.text">{{data.text}}</h1>
  <button class="redirect-modal__explore-button banner__link--animation"
          [style]="{'color': data.button_text_color, 'background': data.button_bg_color}"
          (click)="redirect()">
    {{data.button_title}}
  </button>
  <div class="blind blind--animation-bgd">
    <div *ngFor="let _ of [].constructor(4)"
         class="blind__item"
         [style]="shadowColor"
         [style.background-image]="bgImageUrl"></div>
  </div>
</div>