import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, map } from 'rxjs';

export interface IPInfo {
  ip: string;
  network: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpService) {}

  public getCountry(): Observable<string> {
    return this.http.location.pipe(
      map((locationObject: any) => {
        const { country, country_name } = locationObject as IPInfo;
        return country_name || country;
      }),
    );
  }

  public getAllLocationData(): Observable<IPInfo> {
    return this.http.location as Observable<IPInfo>;
  }

  public getIp(): Observable<string> {
    return this.http.location.pipe(
      map((locationObject: any) => {
        const { ip } = locationObject as IPInfo;
        return ip;
      }),
    );
  }
}
