export enum PageSlugEnum {
  HOME = '',
  ETLANDELT = 'etl-and-elt',
  EXPERTISE = 'expertise',
  ABOUT_US = 'about-us',
  OUR_PORTFOLIO = 'cases',
  CASE = 'case',
  BLOG = 'blog',
  NEWS = 'news',
  RESOURCES = 'resources',
  CONTACT_US = 'contact-us',
  THANK_YOU = 'thank-you',
  INSURTECH_THANK_YOU = 'insurtech-summit-2024/thank-you',
  SEARCH = 'search',
  APPROACH = 'our-approach',
  TESTIMONIALS = 'testimonials',
  PRIVACY = 'privacy',
  COOKIES = 'cookies',
  NOT_FOUND = '404',
  ESIGN = 'esign',
  JOBS_HOMEPAGE = 'jobs',
  JOBS_SEARCH = 'jobs/search',
  JOBS_WHO_WE_ARE = 'jobs/who-we-are',
  JOBS_GLOBAL_LOCAL = 'jobs/global-local',
  JOBS_TALENT_DEVELOPMENT = 'jobs/talent-development',
  AUTHOR = 'blog/author',
  THANK_YOU_UNSUBSCRIPTION = 'thank-you-unsubscription',
  THANK_YOU_UNSUBSCRIPTION_REPORTS = 'thank-you-unsubscription-reports'
}
