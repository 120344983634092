import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollPositions: { [url: string]: [number, number] } = {};

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.initScrollRestoration();
  }
  isHeaderRout: boolean = false;
  resetScrollFromHeader() {
    this.isHeaderRout = true;
  }
  private initScrollRestoration() {
    this.router.events.pipe(filter((e: Event) => e instanceof NavigationStart)).subscribe((e: any) => {
      const currentUrl = this.router.url;
      this.scrollPositions[currentUrl] = this.viewportScroller.getScrollPosition();
    });
    this.router.events.pipe(filter((e: Event) => e instanceof NavigationEnd)).subscribe((e: any) => {
      const currentUrl = e.urlAfterRedirects;

      if (this.isHeaderRout) {
        for (let key in this.scrollPositions) {
          this.scrollPositions[key] = [0, 0];
        }
        this.viewportScroller.scrollToPosition([0, 0]);
        this.isHeaderRout = false;
      } else if (this.scrollPositions[currentUrl]) {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition(this.scrollPositions[currentUrl]);
        }, 1600);
      } else {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
