<div class="redirect-modal-backdrop" *ngIf="isVisible">
  <div class="redirect-modal">
    <button class="redirect-modal__close-button"
      (click)="close()">
      <svg width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M.333 21.546 21.546.333l2.122 2.121L2.454 23.667z" />
        <path d="m2.454.333 21.213 21.213-2.121 2.121L.333 2.454z" />
      </svg>
    </button>
    <h1 class="redirect-modal__header">We’ve completely rebranded, and now we are Symfa.</h1>
    <p class="redirect-modal__description">This is our new website</p>
    <button class="redirect-modal__explore-button banner__link--animation"
      (click)="close()">Explore</button>
    <div class="blind blind--animation-bgd">
      <div class="blind__item"></div>
      <div class="blind__item"></div>
      <div class="blind__item"></div>
      <div class="blind__item"></div>
    </div>
  </div>
</div>
