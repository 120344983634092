// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stage: false,
  development: true,
  originUrl: 'https://dev.symfa.com',
  directusApiUrl: 'https://admin.dev.symfa.com',
  directusEmail: 'hi@symfa.com',
  directusUpdatesFlow: '1435e164-eef3-410a-8eac-d74e2395fcf6',
  directusReportsFlow: 'b7f9bec2-d018-4b31-baf1-fd44c89864ea',
  directusPsw: 'symfa2022',
  searchApiKey: [
    'AIzaSyDq8joowirczN98rjEqGoKxAgWcnc2HNuc',
    'AIzaSyBQLcEWy0t_lQgZmh38CVUYHucU_PR136Y',
    'AIzaSyDQAEJmFGNxZtqv8ZO6fspX6-qNSOh1a4I',
    'AIzaSyBxdKcES_Mf1bpZWGihm0NzwY2eWDZ8Guw',
    'AIzaSyAyl4Ts3lo4aMaXq-up5udvo-1HZxilMZM',
    'AIzaSyCtIAD0ESX_xXQQaTCOvXV9KzIGGPMP83E',
    'AIzaSyC_q2ho834WQos-QRcb4WExl_ZI5we1Fo4',
    'AIzaSyBhZSqAnSFSLhTy8_4cPkLPGPnNBsGedNI',
    'AIzaSyDibRSVk2t8-QmzqxNEwiJOzgJvvB8IJcA',
    'AIzaSyCN0SUv4krl9BcrSmWsBx5HPVxYTsBkGqA',
  ],
  searchId: '8378c9089e20f41f7',
  showDraft: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
