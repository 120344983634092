import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPopup } from '@interfaces/popup.interface';
import { HttpService } from '@services/http.service';
import { map, Subscription, switchMap, tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from '@services/platform.service';

@Component({
  selector: 'symfa-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './symfa-popup.component.html',
  styleUrls: ['./symfa-popup.component.scss'],
})
export class SymfaPopupComponent implements OnInit {
  public data: IPopup | null;
  public bgImageUrl: string;
  public isVisible: boolean = false;
  public shadowColor: string;
  public styles: string;
  private allowedRoutes: string[] = [];
  private sub$ = new Subscription();

  constructor(private router: Router, private http: HttpService, private platformService: PlatformService) {}

  public ngOnInit(): void {
    if (this.platformService.isBrowser) {
      this.sub$.add(
        this.http
          .getCollections('Popup')
          .pipe(
            map((response: any) => response.data as IPopup[]),
            tap((popups) => {
              const activePopups = popups.filter((popupData) => popupData.status);
              this.data = activePopups.length ? activePopups[0] : null;
              if (this.data) {
                this.allowedRoutes = this.data.allowed_pages.split(',').map((route) => route.trim());
              }
            }),
            switchMap(() => this.router.events),
          )
          .subscribe((event) => {
            this.isVisible = false;
            if (this.data && event instanceof NavigationEnd) {
              const currentUrl = event.url;
              if (this.allowedRoutes.includes(currentUrl)) {
                this.bgImageUrl = `url('${this.http.assetsUrl}${this.data.bg_image}')`;
                this.shadowColor = `--shadow-color: ${this.data.shadow_color}`;
                setTimeout(() => {
                  this.isVisible = true;
                }, 3000);
              }
            }
          }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  close(): void {
    this.isVisible = false;
  }

  redirect(): void {
    this.router.navigate(['/contact-us'], {
      queryParams: {
        utm_medium: 'popup',
      },
    });
  }
}
