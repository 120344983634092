import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    const parts = url.split('?');
    const baseUrl = parts[0];
    const queryParams = parts[1];
    return super.parse(baseUrl.toLowerCase() + (queryParams ? '?' + queryParams : ''));
  }
}
