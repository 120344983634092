import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { take } from 'rxjs';
import { PlatformService } from './platform.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TawkChatService {
  private userEmail: string;
  private userName: string;
  private isFirstMessage: boolean = true;
  private tawk: any;

  constructor(private http: HttpService, private platformService: PlatformService) {}

  public initChat() {
    this.tawk = (window as any)['Tawk_API'];
    this.tawk.onLoad = () => {
      if (!this.platformService.isBrowser) return;

      if (environment.showDraft) {
        this.tawk.hideWidget();
        return;
      }

      this.userEmail = localStorage.getItem('userEmail') || '';
      this.userName = localStorage.getItem('userName') || 'Visitor';
      this.tawk.setAttributes({ name: this.userName });
    };

    this.tawk.onVisitorNameChanged = (visitorName: string) => {
      if (!this.platformService.isBrowser) return;

      localStorage.setItem('userName', visitorName);
      this.userName = visitorName;
    };

    this.tawk.onPrechatSubmit = ([email]: { label: string; answer: string }[]) => {
      if (!this.platformService.isBrowser) return;

      localStorage.setItem('userEmail', email.answer);
      this.userEmail = email.answer;
    };

    this.tawk.onChatMessageVisitor = () => {
      if (this.isFirstMessage) {
        this.isFirstMessage = false;
        this.http
          .createContact('Chats', {
            email: this.userEmail,
            name: this.userName,
            page_uri: !this.platformService.isBrowser ? '' : location.href,
            page_name: !this.platformService.isBrowser ? '' : document.title,
          })
          .pipe(take(1))
          .subscribe();
      }
    };
  }
}
