import { convertBool, isBrowser } from '../helpers/env.helper';

export const environment = {
  production: true,
  stage: false,
  development: false,
  originUrl: 'https://symfa.com',
  directusApiUrl: 'https://admin.symfa.com',
  directusEmail: 'hi@symfa.com',
  directusUpdatesFlow: '2e61080a-c901-425e-86d0-d951dffe7594',
  directusReportsFlow: '58dad071-4886-42ea-8716-c3177a243df7',
  directusPsw: 'symfa2022',
  searchApiKey: [
    'AIzaSyDq8joowirczN98rjEqGoKxAgWcnc2HNuc',
    'AIzaSyBQLcEWy0t_lQgZmh38CVUYHucU_PR136Y',
    'AIzaSyDQAEJmFGNxZtqv8ZO6fspX6-qNSOh1a4I',
    'AIzaSyBxdKcES_Mf1bpZWGihm0NzwY2eWDZ8Guw',
    'AIzaSyAyl4Ts3lo4aMaXq-up5udvo-1HZxilMZM',
    'AIzaSyCtIAD0ESX_xXQQaTCOvXV9KzIGGPMP83E',
    'AIzaSyC_q2ho834WQos-QRcb4WExl_ZI5we1Fo4',
    'AIzaSyBhZSqAnSFSLhTy8_4cPkLPGPnNBsGedNI',
    'AIzaSyDibRSVk2t8-QmzqxNEwiJOzgJvvB8IJcA',
    'AIzaSyCN0SUv4krl9BcrSmWsBx5HPVxYTsBkGqA',
  ],
  searchId: '8378c9089e20f41f7',
  showDraft: convertBool(isBrowser && window.env['SHOW_DRAFT']),
};
