import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable, retry } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ICard } from '../interfaces/card.interface';
import { ICommonBtn } from '../interfaces/common-btn.interface';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  private searchApiKeyIndex = 0;

  get apiUrl(): string {
    return environment.directusApiUrl;
  }

  get assetsUrl(): string {
    return `${this.apiUrl}/assets/`;
  }

  get location() {
    return this.http.get('https://ipapi.co/json/');
  }

  login(): Observable<any> {
    const creds = {
      email: environment.directusEmail,
      password: environment.directusPsw,
    };
    return this.http.post(`${this.apiUrl}/auth/login`, creds);
  }

  getCollection(collectionName: string, id: number, fields = '*', filter?: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/items/${collectionName}/${id}?fields=${fields}${filter ? `&filter=${filter}` : ''}`)
      .pipe(shareReplay(1));
  }

  getCollections(collectionName: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/items/${collectionName}`).pipe(shareReplay(1)) as Observable<any>;
  }

  getAllCards(): Observable<ICard[]> {
    return this.getCollections('Cards?limit=-1')
      .pipe(map((data) => (data as any).data))
      .pipe(shareReplay(1)) as Observable<ICard[]>;
  }

  getCommonBtns(): Observable<ICommonBtn> {
    return this.getCollections('Common')
      .pipe(map((data) => (data as any).data))
      .pipe(shareReplay(1)) as Observable<ICommonBtn>;
  }

  getSearchResults(query: string, start: number): Observable<any> {
    const currentApiKey = environment.searchApiKey[this.searchApiKeyIndex];
    return this.http
      .get<any>(
        `https://customsearch.googleapis.com/customsearch/v1?key=${currentApiKey}&cx=${environment.searchId}&q=${query}&start=${start}`,
      )
      .pipe(
        retry(1),
        catchError((error) => {
          if (error.status === 429) {
            if (this.searchApiKeyIndex < environment.searchApiKey.length - 1) {
              this.searchApiKeyIndex++;
              return this.getSearchResults(query, start);
            } else {
              return error;
            }
          }
        }),
      );
  }

  createContact(collectionName: string, body: any): Observable<any> {
    if (environment.showDraft) {
      return EMPTY;
    }
    return this.http.post(`${this.apiUrl}/items/${collectionName}`, body).pipe(
      catchError((e: HttpErrorResponse) => {
        switch (e.status) {
          default:
            console.error(`Status: ${e.status}; Message: ${e.message}`);
            break;
        }
        return EMPTY;
      }),
    );
  }

  deleteSubscriber(flowId: string, id: string): Observable<any> {
    // 58dad071-4886-42ea-8716-c3177a243df7
    // 2e61080a-c901-425e-86d0-d951dffe7594
    return this.http.post(`${this.apiUrl}/flows/trigger/${flowId}`,{id:id});
  }

  uploadFile(file: FormData): Observable<any> {
    if (environment.showDraft) {
      return EMPTY;
    }

    return this.http.post(`${this.apiUrl}/files`, file).pipe(
      catchError((e: HttpErrorResponse) => {
        switch (e.status) {
          default:
            console.error(`Status: ${e.status}; Message: ${e.message}`);
            break;
        }
        return EMPTY;
      }),
    );
  }

  downloadFile(url: string, fileName: string): void {
    this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        take(1),
        catchError((e: HttpErrorResponse) => {
          switch (e.status) {
            default:
              console.error(`Status: ${e.status}; Message: ${e.message}`);
              break;
          }
          return EMPTY;
        }),
      )
      .subscribe((blob) => saveAs(blob, fileName));
  }
}
