import { NgModule } from '@angular/core';
import type { Route } from '@angular/router';
import { RouterModule } from '@angular/router';
import { PageSlugEnum } from '@enums/pages/page-slug.enum';

const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () => import('./pages/homepage/homepage.component').then((c) => c.HomepageComponent),
  },
  {
    path: 'about-us',
    loadComponent: () => import('./pages/about-us/about-us.component').then((c) => c.AboutUsComponent),
  },
  {
    path: 'expertise',
    loadComponent: () => import('./pages/expertise/expertise.component').then((c) => c.ExpertiseComponent),
  },
  {
    path: 'cases',
    loadComponent: () => import('./pages/our-portfolio/our-portfolio.component').then((c) => c.OurPortfolioComponent),
  },
  {
    path: 'cases/:slug',
    loadComponent: () => import('./pages/case/case.component').then((c) => c.CaseComponent),
  },
  {
    path: 'blog',
    loadComponent: () => import('./pages/blog/blog.component').then((c) => c.BlogComponent),
  },
  {
    path: 'blog/:slug',
    loadComponent: () => import('./pages/article/article.component').then((c) => c.ArticleComponent),
  },
  {
    path: 'blog/author/:id',
    loadComponent: () => import('./pages/author/author.component').then((c) => c.AuthorComponent),
  },
  {
    path: 'services/:slug',
    loadComponent: () => import('./pages/landing/landing.component').then((c) => c.LandingComponent),
  },
  {
    path: 'expertise/fintech-development',
    redirectTo: 'expertise/financial-software-development',
  },
  {
    path: 'expertise/:slug',
    loadComponent: () => import('./pages/landing/landing.component').then((c) => c.LandingComponent),
  },
  {
    path: PageSlugEnum.APPROACH,
    loadComponent: () => import('./pages/landing/landing.component').then((c) => c.LandingComponent),
  },
  {
    path: 'news',
    loadComponent: () => import('./pages/news/news.component').then((c) => c.NewsComponent),
  },
  {
    path: 'news/:slug',
    loadComponent: () => import('./pages/news-detail/news-detail.component').then((c) => c.NewsDetailComponent),
  },
  {
    path: 'resources',
    loadComponent: () => import('./pages/resources/resources.component').then((c) => c.ResourcesComponent),
  },
  {
    path: 'resources/:slug',
    loadComponent: () => import('./pages/resource/resource.component').then((c) => c.ResourceComponent),
  },
  {
    path: 'testimonials',
    loadComponent: () => import('./pages/testimonials/testimonials.component').then((c) => c.TestimonialsComponent),
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./pages/contact-us/contact-us.component').then((c) => c.ContactUsComponent),
  },
  {
    path: 'thank-you',
    loadComponent: () => import('./pages/thank-you/thank-you.component').then((c) => c.ThankYouComponent),
  },
  {
    path: 'privacy',
    loadComponent: () =>
      import('./pages/cookies-and-policy/cookies-and-policy.component').then((c) => c.CookiesAndPolicyComponent),
  },
  {
    path: 'cookies',
    loadComponent: () =>
      import('./pages/cookies-and-policy/cookies-and-policy.component').then((c) => c.CookiesAndPolicyComponent),
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/search/search.component').then((c) => c.SearchComponent),
  },
  {
    path: '404',
    loadComponent: () => import('./pages/not-found/not-found.component').then((c) => c.NotFoundComponent),
  },
  {
    path: '503',
    loadComponent: () => import('./pages/not-available/not-available.component').then((c) => c.NotAvailableComponent),
  },
  {
    path: 'esign',
    loadComponent: () => import('./pages/esign/esign.component').then((c) => c.EsignComponent),
  },
  {
    path: 'jobs',
    loadComponent: () => import('./pages/jobs-homepage/jobs-homepage.component').then((c) => c.JobsHomepageComponent),
  },
  {
    path: 'jobs/search',
    loadComponent: () => import('./pages/jobs-search/jobs-search.component').then((c) => c.JobsSearchComponent),
  },
  {
    path: 'jobs/who-we-are',
    loadComponent: () => import('./pages/who-we-are/who-we-are.component').then((c) => c.WhoWeAreComponent),
  },
  {
    path: 'jobs/talent-development',
    loadComponent: () => import('./pages/talent-development/talent-development').then((c) => c.TalentDevelopment),
  },
  {
    path: 'jobs/global-local',
    loadComponent: () => import('./pages/global-local/global-local.component').then((c) => c.GlobalLocalComponent),
  },
  {
    path: 'jobs/:slug',
    loadComponent: () => import('./pages/vacancy/vacancy.component').then((c) => c.VacancyComponent),
  },
  {
    path: 'cv/:id',
    loadComponent: () => import('./pages/cv/cv.component').then((c) => c.CvComponent),
  },
  {
    path: 'insurtech-summit-2024/thank-you',
    loadComponent: () => import('./pages/insurtech-summit-2024-thank-you/insurtech-summit-2024-thank-you.component').then((c) => c.InsurtechSummit2024ThankYouComponent),
  },
  {
    path: 'insurtech-summit-2024',
    loadComponent: () => import('./pages/insurtech-summit-2024-welcome/insurtech-summit-2024-welcome.component').then((c) => c.InsurtechSummit2024Welcome),
  },
  {
    path: 'bcn-it-hub-2025',
    loadComponent: () => import('./pages/bcn-it-hub-2025/bcn-it-hub-2025.component').then((c) => c.BcnItHub2025),
  },
  {
    path: 'bcn-it-hub-2025/thank-you',
    loadComponent: () => import('./pages/bcn-it-hub-2025-thank-you/bcn-it-hub-2025-thank-you.component').then((c) => c.BcnItHub2025ThankYouComponent),
  },
  {
    path: 'unsubscription/:id',
    loadComponent: () => import('./pages/unsubscription/unsubscription.component').then((c) => c.UnsubscriptionComponent),
  },
  {
    path: 'unsubscription-reports/:id',
    loadComponent: () => import('./pages/unsubscription/unsubscription.component').then((c) => c.UnsubscriptionComponent),
  },
  {
    path: 'thank-you-unsubscription',
    loadComponent: () => import('./pages/thank-you-unsubscription/thank-you-unsubscription.component').then((c) => c.ThankYouUnsubscriptionComponent),
  },
  {
    path: 'thank-you-unsubscription-reports',
    loadComponent: () => import('./pages/thank-you-unsubscription-report/thank-you-unsubscription-report.component').then((c) => c.ThankYouUnsubscriptionReportsComponent),
  },

  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'enabled',
    }),
  ],
  declarations: [],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
