declare global {
  interface Window {
    env: {
      [index: string]: any;
    };
  }
}

export const convertBool = (data: string) => {
  return data === 'true'
}

export const isBrowser = typeof window !== 'undefined' && window.env;
