import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RedirectModalComponent } from '@components/redirect-modal/redirect-modal.component';
import { environment } from './environments/environment.prod';
import { SymfaPopupComponent } from '@components/symfa-popup/symfa-popup.component';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './helpers/lowercase-url-serializer';
import { SymfaCookieMessageComponent } from '@components/symfa-cookie-message/symfa-cookie-message.component';
import { PreventSetCookieInterceptor } from './interceptors/prevent-set-cookie.interceptor';
import { ClipboardModule } from 'ngx-clipboard';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": "symfa.com",
    // "expiryDays": 180,
  },
  "position": "bottom-left",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#07524e",
      "text": "#ffffff",
      "link": "#fd8465"
    },
    "button": {
      "background": "#ffffff",
      "text": "#191919",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "revokable": true,
  "content": {
    "message": "We use necessary cookies to make our site work. We’d also like to set non-essential cookies to improve user experience and analyze website traffic. By clicking “Allow cookies”, you agree to our website’s cookies use as described in our Cookie Policy.",
    "dismiss": "Got it!",
    "deny": "Reject All",
    "link": "Learn more and customize",
    "href": `${environment.originUrl}/cookies`,
    "policy": "Cookie Policy"
  },
  "blacklistPage": ["/cookies", "/404", "/503"],
  "autoOpen": false,
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    RedirectModalComponent,
    SymfaPopupComponent,
    SymfaCookieMessageComponent,
    ClipboardModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: PreventSetCookieInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
